import { List } from 'immutable';
export default function shuffle(list) {
  const set = list;
  const size = set.size;
  return list.reduce((acc, _item, i) => {
    const rand = random(0, i);
    if (rand !== i) {
      const randItem = acc.get(rand);
      acc = acc.set(i, randItem);
    }
    const itemAtI = set.get(i);
    return acc.set(rand, itemAtI);
  }, List(new Array(size)));
}
function random(min, max) {
  if (max === null) {
    max = min;
    min = 0;
  }
  return min + Math.floor(Math.random() * (max - min + 1));
}