import { List } from 'immutable';
import shuffle from './shuffle';

/**
 * This is used to fill the startList, with the withList, to achieve the desired totalSize of List
 * in case the startList.size > totalSize, it will trim the startList to be of totalSize.
 */
export default function fill() {
  let startList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : List();
  return totalSize => withList => {
    return startList.size > totalSize ? startList.take(totalSize) : startList.concat(shuffle(withList || List()).take(totalSize - startList.size));
  };
}